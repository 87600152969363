<template>
	<div class="about_US">
		<div class="page_banner">
			<img src="@/assets/images/about/about.jpg" />
			<div class="words">
				<p>
					<span>走进凰聚</span>
					软件定制化服务提供商
				</p>
			</div>
		</div>
		<menu-layout></menu-layout>
		<div class="layout profile clearfix">
			<h1 class="enter">
				凰聚概况
			</h1>
			<p class="f34">
				HUANGJU HOLDINGS
			</p>
			<div class="cont">
					<p>
						河北凰聚科技有限公司位于石家庄市新华区恒大中心1303室，是一家集聚多产业高新技术，科研成果的转化﹑转移的现代技术服务平台。现已有健康﹑环保﹑乡村振兴﹑智能制造﹑数字改造等产业的多家企业﹑研发院所入驻平台。智能化血液净化系统﹑区域健康信息平台﹑油泥低温烘干设备，以全球领先的硬核技术被政府与市场接受与推广。
					</p>
					<p>
						<br>
					</p>
						<img src="@/assets/images/about/_20220904194448.jpg" />
					<p>
						公司现有高素质技术人才及商务人员共36人，其中博士2名，本科﹑硕士及教授级高工以上占百分之九十五。
					</p>
					<p>
						<br>
					</p>
		
					<p>
						公司以敬业的精神，专业的知识，集聚英才，有凰来仪，为政府搭桥梁﹑为企业找人脉﹑为产品找市场的服务宗旨，服务政府﹑服务市场；与科研院所联合开发，协作攻关服务于高新技术产业
					</p>
					<p>
						<br>
					</p>
					
				
				<img src="@/assets/images/about/_20220904194438.jpg" />
						<img src="@/assets/images/about/_20220904194442.jpg" />
						<img src="@/assets/images/about/_20220904194445.jpg" />
						<img src="@/assets/images/about/_20220904194432.jpg" />
			</div>

		</div>

	</div>
</template>
<script>
import menuLayout from "@/components/aboutMenu"
export default ({
	components: {
		menuLayout
	}
});
</script>
